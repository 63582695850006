import { useState, useEffect } from 'react';
import './Joke.css';

function Joke() {
  const [joke, setJoke] = useState([]);
  
    useEffect(() => {
        ladeJoke();
    }, []);

    function ladeJoke() {
      fetch('https://v2.jokeapi.dev/joke/Any?safe-mode')
        .then((response) => response.json())
        .then((data) => setJoke(data));
    }

    const isTwoPartJoke = joke.setup && joke.delivery;

      return (
        <div className="joke"
              onClick={ladeJoke}>
          <h2>{joke.joke}</h2>
          <h2>{joke.setup}</h2>
          <h3>{joke.delivery}</h3>
        </div>
      );

}

export default Joke;