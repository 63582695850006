import Item from "./Item";

function Itemslist({itemslist, onCheckBoxChange}) {
    
    // const items = itemslist.forEach(item => {
    //     <Item key={item.id} item={item} onBoxChange={onCheckBoxChange}/>
    // });

    const items = itemslist.map(item =>
        <Item key={item.id} item={item} handleClick={onCheckBoxChange} /> 
      );
  return (
    items
  );
}

export default Itemslist;