import "./Finance.css";
import { useState, useEffect } from "react";

function Finance() {
  const [courseBitcoin, setBitcoin] = useState([]);
  const [courseEthereum, setEthereum] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetch("https://api.coinbase.com/v2/exchange-rates?currency=BTC")
        .then((response) => response.json())
        .then((data) => setBitcoin(data.data.rates.EUR));
      fetch("https://api.coinbase.com/v2/exchange-rates?currency=ETH")
        .then((response) => response.json())
        .then((data) => setEthereum(data.data.rates.EUR));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="finance">
      <h2>Cryptor courses</h2>
      <table>
        <thead>
          <tr>
            <th>Crypto-Asset</th>
            <th>EUR</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>BTC</td>
            <td>
              {Number(courseBitcoin)
                ? Number.parseFloat(courseBitcoin).toFixed(2)
                : "loading..."}
            </td>
          </tr>
          <tr>
            <td>ETH</td>
            <td>
              {Number(courseEthereum)
                ? Number.parseFloat(courseEthereum).toFixed(2)
                : "loading..."}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Finance;
