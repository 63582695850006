import { useEffect, useState } from "react";
import AddButton from "./AddButton";
import DeleteButton from "./DeleteButton";
import Itemslist from "./Itemslist";
import "./TodoApp.css";

function TodoApp() {
  //The State of our app: all todos live here
  const [todos, setTodos] = useState([]);

  useEffect(() => {
    var itemslist = JSON.parse(localStorage.getItem("itemsLocal"));
    if (!itemslist) {
      itemslist = [
        {
          id: 1,
          task: "A task that needs to be done within a certain period of time.",
          taskstatus: false,
        },
        {
          id: 2,
          task: "Another task that needs to be done within a certain period of time.",
          taskstatus: true,
        },
      ];
    }
    setTodos(itemslist);
  }, []);

  function handleAddButtonClick() {
    //const todotext = prompt('Please enter a new ToDo');
    const inputTodo = document.getElementById("inputTodo");
    const todotext = inputTodo.value;
    inputTodo.value = "";
    const updatedTodos = [
      ...todos,
      { id: Date.now(), task: todotext, taskstatus: false },
    ];
    localStorage.setItem("itemsLocal", JSON.stringify(updatedTodos));
    setTodos(updatedTodos);
  }

  function handleDeleteButtonClick() {
    const bereinigteTodos = todos.filter((todo) => todo.taskstatus == false);
    localStorage.setItem("itemsLocal", JSON.stringify(bereinigteTodos));
    setTodos(bereinigteTodos);
  }

  function handleCheckBoxChange(id) {
    // alert('You clicked me:'+id);
    const updatedTodos = todos.map((todo) => {
      if (todo.id == id) {
        return { ...todo, taskstatus: !todo.taskstatus };
      }
      return todo;
    });
    localStorage.setItem("itemsLocal", JSON.stringify(updatedTodos));
    setTodos(updatedTodos);
  }

  return (
    <div className="todo">
      <h2>My ToDos</h2>
      <AddButton todos={todos} onButtonClick={handleAddButtonClick} />
      <ul>
        <Itemslist itemslist={todos} onCheckBoxChange={handleCheckBoxChange} />
      </ul>
      <DeleteButton todos={todos} onButtonClick={handleDeleteButtonClick} />
    </div>
  );
}

export default TodoApp;
