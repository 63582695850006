import { useState } from "react";

function AddButton({ value, onButtonClick }) {
  return (
    <div>
      <input
        id="inputTodo"
        type="text"
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onButtonClick();
          }
        }}
      ></input>
      <button title="Adds a new ToDo" onClick={()=>onButtonClick()}>
        +
      </button>
    </div>
  );
}

export default AddButton;
