export default function DeleteButton({ onButtonClick }) {
    return (
      <div>
        <button title="Get rid of all done tasks" onClick={onButtonClick}>
          Delete all checked tasks
        </button>
      </div>
    );
  }
  
  