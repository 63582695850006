function Item({item, handleClick}) {
//   function handleClick() {
//     alert('You clicked me!');
//     item.taskstatus = !item.taskstatus;
//   }

  return (
    <li><input type='checkbox' checked={item.taskstatus} onChange={()=>handleClick(item.id)} />{item.task}</li>
  );
}

export default Item;