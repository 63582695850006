import React, { useState } from 'react';
import './App.css';
import Joke from './Joke/Joke.js';
import Image from './Image/Image.js';
import Finance from './Finance/Finance.js';
import TodoApp from './Todo/TodoApp.js';

// Import your images
import laughIcon from './assets/img/laugh.png';
import doggoIcon from './assets/img/pets.png';
import bitcoinIcon from './assets/img/bitcoin.png';
import noteIcon from './assets/img/notes.png';

function App() {
  const [toggleStates, setToggleStates] = useState([false, false, false, false]);
  const [jokeVisible, setJokeVisible] = useState(true);
  const [imageVisible, setImageVisible] = useState(true); 
  const [bitcoinVisible, setBitcoinVisible] = useState(true); // New state for bitcoin visibility
  const [noteVisible, setNoteVisible] = useState(true); // New state for note visibility

  const handleToggleClick = (index) => {
    const newToggles = [...toggleStates];
    newToggles[index] = !newToggles[index];
    setToggleStates(newToggles);

    // Toggle joke visibility when laugh icon is clicked (index 0)
    if (index === 0) {
      setJokeVisible(!jokeVisible);
    }

    // Toggle image visibility when doggo icon is clicked (index 1)
    if (index === 1) {
      setImageVisible(!imageVisible);
    }

    // Toggle bitcoin visibility when bitcoin icon is clicked (index 2)
    if (index === 2) {
      setBitcoinVisible(!bitcoinVisible);
    }

    // Toggle note visibility when note icon is clicked (index 3)
    if (index === 3) {
      setNoteVisible(!noteVisible);
    }
  };

  return (
    <>
      <header>Design.grid</header>

      <nav className="top-nav">
        <ul>
          <li>
            <img className="nav-icon" src={laughIcon} alt="Funny" />
            <label className="switch">
              <input type="checkbox" checked={toggleStates[0]} onChange={() => handleToggleClick(0)} />
              <span className="slider round"></span>
            </label>
          </li>
          <li>
            <img className="nav-icon" src={doggoIcon} alt="Dogggoooo" />
            <label className="switch">
              <input type="checkbox" checked={toggleStates[1]} onChange={() => handleToggleClick(1)} />
              <span className="slider round"></span>
            </label>
          </li>
          <li>
            <img className="nav-icon" src={bitcoinIcon} alt="Bitcoin" />
            <label className="switch">
              <input type="checkbox" checked={toggleStates[2]} onChange={() => handleToggleClick(2)} />
              <span className="slider round"></span>
            </label>
          </li>
          <li>
            <img className="nav-icon" src={noteIcon} alt="Notes" />
            <label className="switch">
              <input type="checkbox" checked={toggleStates[3]} onChange={() => handleToggleClick(3)} />
              <span className="slider round"></span>
            </label>
          </li>
        </ul>
      </nav>
      <main className='dashboard'>
        {/* Conditionally render Joke based on jokeVisible state */}
        {jokeVisible && <Joke />}
        {/* Conditionally render Image based on imageVisible state */}
        {imageVisible && <Image />}
        {/* Conditionally render Image based on imageVisible state */}
        {bitcoinVisible && <Finance />}
        {/* Conditionally render Image based on imageVisible state */}
        {noteVisible && <TodoApp />}
      </main>
      <nav className="bottom-nav">
        <ul>
          <li>
            <img className="nav-icon" src={laughIcon} alt="Funny" />
            <label className="switch">
              <input type="checkbox" checked={toggleStates[0]} onChange={() => handleToggleClick(0)} />
              <span className="slider round"></span>
            </label>
          </li>
          <li>
            <img className="nav-icon" src={doggoIcon} alt="Dogggoooo" />
            <label className="switch">
              <input type="checkbox" checked={toggleStates[1]} onChange={() => handleToggleClick(1)} />
              <span className="slider round"></span>
            </label>
          </li>
          <li>
            <img className="nav-icon" src={bitcoinIcon} alt="Bitcoin" />
            <label className="switch">
              <input type="checkbox" checked={toggleStates[2]} onChange={() => handleToggleClick(2)} />
              <span className="slider round"></span>
            </label>
          </li>
          <li>
            <img className="nav-icon" src={noteIcon} alt="Notes" />
            <label className="switch">
              <input type="checkbox" checked={toggleStates[3]} onChange={() => handleToggleClick(3)} />
              <span className="slider round"></span>
            </label>
          </li>
        </ul>
      </nav>
      <footer>(c)Lee Gritsch</footer>
    </>
  );
}

export default App;
