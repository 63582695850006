import { useEffect } from 'react';
import { useState } from 'react';
import './Image.css';

function Image() {
  const [picture, setPicture] = useState([]);

  useEffect(() => {
    fetch('https://dog.ceo/api/breeds/image/random')
      .then((response) => response.json())
      .then((data) => setPicture(data));
  }, []);

  const handleClick = (event) => {
    window.open(picture.message)
  };

  return (
    <div className="image">
      <h2>For you</h2>
      <img src={picture.message} alt="A random image" onClick={handleClick} />
    </div>
  );
}

export default Image;
